<template>
  <div class="modal" :class="{'is-active': show}">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-header">
        <button class="close modal-close" @click="close"></button>
        <h3 class="modal-title">{{ title }}</h3>
      </header>
      <section class="modal-card-body">
        <slot />
      </section>
      <footer class="modal-card-foot" v-if="$slots.footer">
        <slot name="footer" />
      </footer>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: String
    },
    data() {
      return {
        show: false
      }
    },
    methods: {
      keypressed(key) {
        if (key.code === 'Escape') {
          this.close()
        }
      },
      open() {
        this.show = true
        document.body.classList.add('menu-opened')
        document.addEventListener('keydown', this.keypressed)
        this.$emit("opened")
      },
      close() {
        this.show = false
        document.body.classList.remove('menu-opened')
        document.removeEventListener('keydown', this.keypressed)
        this.$emit("closed")
      }
    }
  }
</script>
