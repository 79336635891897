<template>
  <form class="reveal-2">
    <div class="txtcenter" v-if="feedback">
      Merci, votre demande de contact a bien été enregistrée.
    </div>
    <div v-else>
      <div class="grid-2 has-gutter-xl">
        <input type="text" v-model.trim="model.last_name" placeholder="Nom*" required id="contact-last_name">
        <input type="text" v-model.trim="model.first_name" placeholder="Prénom*" required id="contact-first_name">
        <input type="email" v-model.trim="model.email" placeholder="Email*" required id="contact-email">
        <input type="text" v-model.trim="model.phone" placeholder="Numéro de téléphone*" required id="contact-phone">
        <input type="text" v-model.trim="model.address" placeholder="Adresse*" required id="contact-address">
      </div>
      <textarea cols="30" rows="7" v-model.trim="model.message" placeholder="Votre message*"></textarea>
      <button type="button" class="btn btn--black" @click.prevent="submit" :disabled="invalid || submitting">
        {{ submitting ? 'Patienter...' : 'Valider' }}
      </button>
      <span class="small-infos txtright">Champs obligatoires *</span>
    </div>
  </form>
</template>

<script>
  import axios from 'axios'
  export default {
    props: {
      url: String
    },
    data() {
      return {
        submitting: false,
        feedback: false,
        model: {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          message: ''
        }
      }
    },
    computed: {
      invalid() {
        return (
          this.model.first_name == '' ||
          this.model.last_name == '' ||
          !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.model.email)) ||
          this.model.phone == '' ||
          this.model.message == ''
        )
      }
    },
    methods: {
      submit() {
        if (this.submitting) return
        this.submitting = true
        axios.post(this.url, {
          contact: this.model
        }).then(response => {
          this.submitting = false
          this.feedback = true
          this.model = {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            message: ''
          }
        })
      }
    }
  }
</script>
