<template>
  <div>
    <h2 class="txtcenter reveal-1">Notre Carte</h2>
    <div class="filter-wrapper reveal-1">
      <div class="filter">
        <a
          v-for="category in categories"
          :key="category.id"
          class="filter-item"
          :class="{'is-active': currentCategory == category}"
          href=""
          @click.prevent="currentCategory = category">
          {{ category.title }}
        </a>
      </div>
    </div>
    <div class="list-card">
      <div
        v-for="product in categorizedProducts"
        :key="product.id"
        class="list-card-item reveal-1">
        <div class="visuel">
          <img :src="product.picture_url">
        </div>
        <div class="description">
          <div class="z-info">
            <div class="name">
              {{ product.title }}
            </div>
            <div class="food-ingredient">
              <span v-if="product.ingredients">
                {{ product.ingredients.join(', ')}}
              </span>
            </div>
          </div>
          <div class="z-info-bis">
            <div class="price">
              {{ product.price | amount }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal ref="optionsModal" v-if="currentSite" title="Choix des options">
      <div class="extras" v-for="group in currentOptionsGroups">
        <div v-if="group.length == 1">
          <div class="extra-item" v-for="option in group" :key="option.id">
            <div class="extra-name">
              {{ option.title }}
            </div>
            <div class="extra-price">
              {{ option.price | amount }}
            </div>
            <div class="extra-add">
              <button @click="decrementOption(option)" :disabled="quantityOption(option) == 0">
                <span><i class="far far-minus"></i></span>
              </button>
              <input type="number" class="qty" :value="quantityOption(option)">
              <button @click="incrementOption(option)" :disabled="quantityOption(option) >= option.maximum">
                <span><i class="far far-plus"></i></span>
              </button>
            </div>
          </div>
        </div>
        <div class="extra-item" v-else-if="group.length > 1">
          <div class="extra-name">
            Votre choix
          </div>
          <div class="extra-price"></div>
          <div class="extra-add">
            <select class="field" @change="selectOption($event.target.value)">
              <option v-for="option in group" :value="option.id">{{ optionPrompt(option) }}</option>
            </select>
          </div>
        </div>
      </div>
      <div slot="footer" class="btn-group">
        <button class="btn btn--close" @click="cancelProduct()">Annuler</button>
        <button class="btn btn--black" @click="submitProduct()" :disabled="busy">
          {{ busy ? 'Patienter...' : 'Valider' }}
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
  import axios from 'axios'
  import FrontModal from '../../ui/FrontModal'
  import Amount from '../../filters/Amount'
  import { OrderBus } from '../../packs/front'
  export default {
    components: {
      modal: FrontModal
    },
    filters: {
      Amount
    },
    props: {
      categories: Array,
      products: Array,
      sites: Array
    },
    data() {
      return {
        order: {},
        busy: false,
        currentSite: this.sites[0],
        currentCategory: this.categories[0],
        currentProduct: {},
        orderItem: { options: [] }
      }
    },
    mounted() {
      OrderBus.$on('order:updated', (order) => {
        this.order = order
        this.currentSite = this.sites.find((site) => site.id == this.order.site_id)
      })
    },
    computed: {
      categorizedProducts() {
        return this.products.filter((product) => {
          return product.category_id == this.currentCategory.id
        })
      },
      currentOptionsGroups() {
        if (this.currentOptions) {
          return this.currentOptions.reduce((result, option) => {
            (result[option.group] = result[option.group] || []).push(option)
            return result
          }, {})
        }
      },
      currentOptions() {
        if (this.currentProduct && this.currentProduct.options) {
          return this.currentProduct.options.sort((a, b) => a.position - b.position)
        }
      }
    },
    methods: {
      isAvailable(product) {
        if (product && product.stocks && this.order.site_id) {
          return product.stocks.find((stock) => stock.site_id == this.order.site_id).available
        } else {
          return true
        }
      },
      submitProduct() {
        if (this.busy) return
        this.busy = true
        axios.post(
          this.order.items_url, {
            order_item: this.orderItem
          }
        ).then((response) => {
          OrderBus.$emit('order:updated', response.data)
          this.busy = false
          Turbolinks.visit(this.order.url)
        }).catch((error) => {
          console.error(error)
          this.busy = false
        })
      },
      filteredProducts(category) {
        return this.products.filter((product) => {
          return product.category == category
        })
      },
      newProduct(product) {
        this.currentProduct = product
        this.orderItem = {
          target_type: 'Product',
          target_id: product.id,
          options: []
        }
        if (this.currentProduct.options.length > 0) {
          this.defaultOptions(this.currentProduct).forEach((option) => {
            this.incrementOption(option)
          })
          this.$refs.optionsModal.open()
        } else {
          if (this.isAvailable(product)) {
            this.submitProduct()
          }
        }
      },
      optionPrompt(option) {
        if (option.price > 0) {
          return `${option.title} (+ ${this.$options.filters.Amount(option.price)})`
        } else {
          return `${option.title} (inclus)`
        }
      },
      defaultOptions(product) {
        return product.options.filter((o) => o.default)
      },
      quantityOption(option) {
        const current = this.orderItem.options.find((item) => item.target_id == option.id)
        if (current) {
          return current.quantity
        } else {
          return 0
        }
      },
      removeSameGroupOptions(option) {
        const optionsFromSameGroup = this.currentProduct.options.filter((o) => {
          return o.id != option.id && o.group == option.group
        })
        optionsFromSameGroup.forEach((ofsg) => {
          const index = this.orderItem.options.findIndex((o) => o.target_id == ofsg.id)
          if (index >= 0) {
            this.orderItem.options.splice(index, 1)
          }
        })
      },
      decrementOption(option) {
        const index = this.orderItem.options.findIndex((o) => o.target_id == option.id)
        if (index >= 0) {
          const foundOption = this.orderItem.options[index]
          if (foundOption.quantity == 1) {
            this.orderItem.options.splice(index, 1)
          } else {
            foundOption.quantity -= 1
          }
        }
      },
      incrementOption(option) {
        let current = this.orderItem.options.find((o) => o.target_id == option.id)
        if (current) {
          if (option.maximum == null || current.quantity < option.maximum) {
            current.quantity += 1
          }
        } else {
          current = {
            target_type: 'ProductOption',
            target_id: option.id,
            quantity: 1
          }
          this.orderItem.options.push(current)
        }
        this.removeSameGroupOptions(option)
      },
      selectOption(id) {
        const optionId = parseInt(id)
        const option = this.currentOptions.find((item) => item.id == optionId)
        this.incrementOption(option)
      },
      cancelProduct() {
        this.currentProduct = {}
        this.orderItem = {}
        this.$refs.optionsModal.close()
      }
    }
  }
</script>
