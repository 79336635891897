<template>
  <a href="/commande" class="menu-cart-price">
    <span class="badge">{{ order.quantity }}</span>
    <i class="fas fas-shopping-bag"></i>
    {{ order.total_amount | amount }}
  </a>
</template>

<script>
  import { OrderBus } from '../../packs/front'
  import Amount from '../../filters/Amount'
  export default {
    filters: {
      Amount
    },
    data() {
      return {
        order: {}
      }
    },
    mounted() {
      OrderBus.$on('order:updated', (order) => {
        this.order = order
      })
    },
    computed: {
      empty() {
        return this.order.quantity == 0
      }
    }
  }
</script>
