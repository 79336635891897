<template>
  <section class="section-cart">
    <div class="container container-sm">
      <div class="reveal" v-show="step == 'summary'">
        <div class="cart is-empty" v-if="empty">
          <i class="fas fas-sad-cry"></i>
          <div class="title">Votre panier est vide</div>
          <a href="/" class="btn btn--black">Retour</a>
        </div>
        <div v-else>
          <div class="alert reveal-1" v-if="order.transaction_failed">
            <i class="fas fas-exclamation-circle"></i>
            <div class="alert-title">Problème de paiement</div>
            <p class="alert-p">Notre système a détecté que votre denière tentative de paiement a échoué. Vous pouvez cependant tenter un nouveau paiement.</p>
          </div>
          <h1 class="title-main mbl reveal-1">Récapitulatif <br> de commande</h1>
          <div class="cards cart-sum">
            <div class="card reveal-2" v-for="item in order.items" :key="item.id">
              <div class="flex-container">
                <button @click="removeItem(item)">
                  <i class="far far-times"></i>
                </button>
                <div>
                  <div class="title">{{ item.product.title }}</div>
                  <div class="text-meta">{{ item.product.ingredients.join(', ') }}</div>
                </div>
                <div class="price">{{ item.amount | amount }}</div>
              </div>
              <div class="flex-container" :class="{sep: (index == 0)}" v-for="(option, index) in orderedOptions(item.options)" :key="option.id">
                <div class="text-meta">{{ option.product.title }} </div>
                <div class="text-meta text-meta-black" v-if="option.amount > 0">
                  {{ option.amount | amount }}
                </div>
                <div class="text-meta" v-else>
                  <i>Inclus</i>
                </div>
              </div>
            </div>
            <div class="card card--pal0 reveal-2">
              <div class="flex-container">
                <div class="title">Total TTC</div>
                <div class="price">{{ order.total_amount | amount }}</div>
              </div>
              <div class="flex-container sep" v-for="(amount, vat) in order.vat_parts">
                <div class="text-meta">TVA {{ vat | percentage }}</div>
                <div class="price price-sm">{{ amount | amount }}</div>
              </div>
            </div>
            <div class="btn-group btn-group--form reveal-3">
              <a href="/" class="btn btn--link">
                <i class="far far-chevron-left"></i>
                Ajouter d'autres produits
              </a>
              <button @click="step = 'pickup'" class="btn btn--black">Valider la commande</button>
            </div>
          </div>
        </div>
      </div>
      <div v-show="step == 'pickup'">
        <div class="reveal">
          <h1 class="title-main mbl reveal-1">Retrait de votre <br> commande</h1>
          <div class="cards cart-pickUp">
            <div class="card reveal-2">
              <div class="flex-container mbm">
                <i class="far far-clock"></i>
                <div class="title">À quelle heure souhaitez-vous récupérer votre commande au restaurant ?</div>
              </div>
              <select class="field mbm" ref="scheduled_at" v-model="model.scheduled_at">
                <optgroup v-for="pickup in pickups" :label="pickup.day" v-if="pickup.times.length > 0">
                  <option v-for="time in pickup.times" :value="time">
                    {{ time | time('HH:mm') }}
                  </option>
                </optgroup>
              </select>
              <input type="text" class="field" placeholder="Votre nom *" v-model.trim="model.first_name">
            </div>
            <div class="card reveal-2">
              <div class="flex-container mbm">
                <i class="fas fas-comment"></i>
                <div class="title">Recevoir un SMS lorsque votre commande est prête ?</div>
              </div>
              <input type="tel" class="field" placeholder="Votre numéro de téléphone" v-model.trim="model.phone" v-mask="'0# ## ## ## ##'">
            </div>
            <div class="btn-group btn-group--form reveal-3">
              <div class="meta">
                <div class="meta-p">
                  <i class="fas fas-lock"></i>
                  Paiement en ligne sécurisé
                </div>
                <div class="meta-icons">
                  <i class="fab fab-cc-visa"></i>
                  <i class="fab fab-cc-mastercard"></i>
                  <i class="fab fab-cc-amex"></i>
                </div>
              </div>
              <button
                class="btn btn--black"
                :class="{'is-disabled': (busy || !valid)}"
                @click="submitOrder()"
                :disabled="busy || !valid">
                <i class="fas fas-credit-card"></i> Payer la commande
              </button>
            </div>
            <div class="required-fields reveal-3">* Champs obligatoires</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { format, add, set, isBefore, isToday, isTomorrow, getDay, parseISO, formatISO } from 'date-fns'
  import { fr } from 'date-fns/locale'
  import axios from 'axios'
  import { mask } from 'vue-the-mask'
  import Amount from '../../filters/Amount'
  import Time from '../../filters/Time'
  import Percentage from '../../filters/Percentage'
  import { OrderBus } from '../../packs/front'
  export default {
    directives: {
      mask
    },
    filters: {
      Amount,
      Time,
      Percentage
    },
    data() {
      return {
        step: 'summary',
        order: {
          items: []
        },
        transaction: null,
        model: {
          first_name: null,
          scheduled_at: null
        },
        busy: false
      }
    },
    mounted() {
      OrderBus.$on('order:updated', (order) => {
        this.order = order
        this.model = {
          first_name: order.first_name,
          scheduled_at: this.pickups.flatMap((pickup) => pickup.times)[0]
        }
      })
    },
    computed: {
      valid() {
        return (
          this.model.first_name != null &&
          this.model.first_name != '' &&
          this.model.scheduled_at != null &&
          (
            this.model.phone == null ||
            this.model.phone == '' ||
            this.model.phone.replace(/\s/g, '').match(/0[6,7]\d{8}/) != null
          )
        )
      },
      empty() {
        return this.order.items.length == 0
      },
      pickups() {
        const openingHours = [{
          open: true,
          from: {
            hours: 10,
            minutes: 0
          },
          to: {
            hours: 16,
            minutes: 30
          }
        }, {
          open: true,
          from: {
            hours: 9,
            minutes: 0
          },
          to: {
            hours: 14,
            minutes: 30
          }
        }, {
          open: true,
          from: {
            hours: 9,
            minutes: 0
          },
          to: {
            hours: 14,
            minutes: 30
          }
        }, {
          open: false
        }, {
          open: true,
          from: {
            hours: 9,
            minutes: 0
          },
          to: {
            hours: 14,
            minutes: 30
          }
        }, {
          open: true,
          from: {
            hours: 9,
            minutes: 0
          },
          to: {
            hours: 16,
            minutes: 30
          }
        }, {
          open: true,
          from: {
            hours: 9,
            minutes: 30
          },
          to: {
            hours: 16,
            minutes: 30
          }
        }]

        let pickups = []
        let currentDate = new Date()
        let startingDay = getDay(currentDate)
        for (let index = 0; index < 2; index++) {
          let currentDay = (startingDay + index) % 7
          let currentHours = openingHours[currentDay]
          if (currentHours.open) {
            let date = add(currentDate, { days: index })
            let lower = set(date, { hours: currentHours.from.hours, minutes: currentHours.from.minutes, seconds: 0, milliseconds: 0 })
            let upper = set(date, { hours: currentHours.to.hours, minutes: currentHours.to.minutes, seconds: 0, milliseconds: 0 })
            let slot = lower
            let group = {}
            if (isToday(slot)) {
              group = { day: "Aujourd'hui", times: [] }
            } else if (isTomorrow(slot)) {
              group = { day: "Demain", times: [] }
            } else {
              group = { day: format(slot, 'EEEE', { locale: fr }), times: [] }
            }
            while (!isBefore(upper, slot)) {
              if (isBefore(currentDate, slot)) {
                group.times.push(formatISO(slot))
              }
              slot = add(slot, { minutes: 30 })
            }
            pickups.push(group)
          }
        }
        return pickups
      },
      currentSite() {
        return this.sites.find((site) => site.id == this.order.site_id)
      }
    },
    methods: {
      orderedOptions(options) {
        return [...options].sort((a, b) => a.product.position - b.product.position)
      },
      removeItem(item) {
        axios.delete(item.url).then((response) => {
          OrderBus.$emit('order:updated', response.data)
        })
      },
      submitOrder() {
        if (this.busy) return
        this.busy = true
        axios.patch(
          this.order.url, {
            order: this.model
          }
        ).then((response) => {
          axios.post(
            this.order.transaction_url
          ).then((response) => {
            this.transaction = response.data
            const stripe = Stripe(this.transaction.stripe_public_api_key)
            return stripe.redirectToCheckout({ sessionId: this.transaction.id })
          })
        }).catch((error) => {
          this.busy = false
          console.error(error)
        })
      }
    },
    watch: {
      step() {
        window.scrollTo(0, 0)
      },
      'model.scheduled_at': function(value) {
        let text = ''
        if (value == null) {
          text = "Dès que possible"
        } else {
          let date = parseISO(value)
          if (isToday(date)) {
            text = `Aujourd'hui à ${format(date, 'HH:mm')}`
          } else if (isTomorrow(date)) {
            text = `Demain à ${format(date, 'HH:mm')}`
          } else {
            text = format(date, 'EEEE à HH:mm', { locale: fr })
          }
        }
        this.$nextTick(() => {
          this.$refs.scheduled_at.selectedOptions[0].text = text
        })
      }
    }
  }
</script>
