// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
Vue.use(TurbolinksAdapter)

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

import axios from 'axios'
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name=csrf-token]").content

import ProductIndex from '../components/front/ProductIndex'
Vue.component('product-index', ProductIndex)
import Cart from '../components/front/Cart'
Vue.component('cart', Cart)
import OrderForm from '../components/front/OrderForm'
Vue.component('order-form', OrderForm)
import ContactForm from '../components/front/ContactForm'
Vue.component('contact-form', ContactForm)

export const OrderBus = new Vue()

document.addEventListener('turbolinks:load', () => {
  new Vue({
    el: '#application',
    data() {
      return {
        order: {}
      }
    },
    mounted() {
      this.order = JSON.parse(this.$el.dataset.order)
      OrderBus.$emit('order:updated', this.order)

      // Tout ceci est du code legacy, je n'en suis pas responsable :)

      const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.js-menuburger'), 0);
      if ($navbarBurgers.length > 0) {
        $navbarBurgers.forEach(function(el) {
          el.addEventListener('click', function() {
            let target = el.dataset.target
            let $target = document.querySelector(target)
            let $body = document.body

            el.classList.toggle('is-active')
            $target.classList.toggle('is-open')
            $body.classList.toggle('menu-opened')

            // Au clic sur un lien (en mobile), on ferme le panneau de navigation
            const links = document.querySelectorAll('.main-menu .item-menu')

            links.forEach(function(link) {
              link.addEventListener('click', function() {
                el.classList.remove('is-active')
                $target.classList.remove('is-open')
                $body.classList.remove('menu-opened')
              })
            })
          });
        });
      }

      const $header = document.querySelector('.page-home .header')
      document.addEventListener('scroll', function(event) {
        if (window.pageYOffset > 50) {
          $header.classList.add('header--black')
        } else {
          $header.classList.remove('header--black')
        }
      })

      const video = document.querySelector('#video-presentation')
      if (video) {
        const playButton = document.querySelector('.js-play')
        function togglePlay() {
          if (video.paused || video.ended) {
            video.play();
            playButton.style.opacity = '0'
          } else {
            video.pause();
            playButton.style.opacity = '1'
          }
        }
        video.addEventListener('click', togglePlay)
      }

      const observer = new IntersectionObserver(this.reveal , {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      })
      document.querySelectorAll('.reveal').forEach(r => observer.observe(r))
    },
    methods: {
      reveal(entries, observer) {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0.1) {
            entry.target.classList.remove('reveal')
            observer.unobserve(entry.target)
          }
        })
      }
    }
  })
})
